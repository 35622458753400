<template>
  <div class="search-statistics-container">
    <div class="top-container">
      <a-row>
        <a-form-model
          layout="inline"
          style="display: flex; justify-content: space-between"
        >
          <a-form-model-item label="关键词">
            <a-input
              placeholder="请输入"
              v-model="queryFrom.keyword"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>

          <div class="right" style="line-height: 4rem">
            <a-button type="reload" @click="handleReset">重置</a-button>
            <a-button type="primary" @click="queryBtn">查询</a-button>
          </div>
        </a-form-model>
      </a-row>
    </div>

    <div class="bottom-container">
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 460 }"
        :pagination="pagination"
      ></a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "关键词",
    ellipsis: true,
    dataIndex: "keyword",
  },
  {
    title: "搜索次数",
    ellipsis: true,
    dataIndex: "searchNum",
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { API } from "../../apix/searchKeyword";

export default {
  mixins: [pagination, mixins],
  data() {
    return {
      columns,
      data: [],
      loading: false,
      queryFrom: {
        keyword: null,
      },
    };
  },
  methods: {
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      this.queryFrom.keyword = null;
      this.getSourceData();
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  height: 10rem;
}
.ant-input {
  width: 28.5rem;
}
</style>
